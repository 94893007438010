import React from 'react'
import { Link } from "gatsby"
import { Container, Row, Col, Button } from 'reactstrap';
import Header from '../components/Header';
import Footer from '../components/Footer'
import Helmet from 'react-helmet'
import styled from 'styled-components';
import Layout from '../components/Layout'

export default ({data}) => (
  <Layout>
    <Helmet
      title="Page Not Found | Bishop Software"
      meta={[
        { name: 'description', content: 'Welcome to the twilight zone.' },
        { name: 'keywords', content: '404,not found,twilight,zone' }
      ]}
    />

    <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, #14134e 0, #512d5a 56%, #843b61 81%, #c16858 100%)', color: '#fafafa'}}>
      <Header dropdownGradient='linear-gradient(180deg, #d15e95 0, #c45d50 100%)' />

      <Container className="text-center" style={{paddingTop: '1.875rem'}}>
          <div style={{margin: '55px 0 0 0'}}>
            <h1 style={{color: '#ffc092', fontSize: '40px', fontWeight: 300}}>
              Uh-oh.
            </h1>
            <h3 style={{fontSize: '18px', padding: '25px 0 45px 0', fontWeight: 400, lineHeight: '24px'}}>We can't find your page.</h3>
          </div>
        </Container>
    </section>

    <section style={{padding: '70px 0'}}>
      <Container>
        <Row>
          <Col>
            <h4 style={{marginBottom: '25px'}}>If you were trying to reach another page, perhaps you can find it below.</h4>
            <ul>
              {data.allSitePage.edges.filter(({node}) => node.path != '/dev-404-page/' && node.path != '/404/' && node.path != '/404.html').map(({node}) => (
                <li><Link to={node.path}>{node.path}</Link></li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>

      <Footer />
  </Layout>
)

export const query = graphql`
  query FourOhFourQuery {
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`